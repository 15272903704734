import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Header from "../../features/Layout/Header"
import Heading from "../../features/Heading/index"
import Footer from "../../features/Layout/Footer"
import './Posts.css'
import "./Slider.css";
import Slider from "./Slider";
import ReactDOM from "react-dom";




export class Posts extends Component {
   state = {
       posts: [],
       isLoaded: false
   }

   constructor (props) {
    super(props)
   }


   componentDidMount () {
        const { pathname } = this.props.location;     
        if (pathname !== '/' && !pathname.endsWith('/')) { 
            this.props.history.push(`${pathname}/`); 
        }

        //https://blog.afnu.co.il/wp-json/wp/v2/posts?slug=about
        axios.get(`https://blog.afnu.co.il/wp-json/wp/v2/posts/`)
            .then(res => this.setState({
                posts: res.data,
                isLoaded: true,
                inlineStyles: this.inlineStyles,
            }, this.initializeSliders))
            .catch(err => console.log(err));


   }
   
   initializeSliders = () => {
        const galleryElements = document.querySelectorAll(".pgc-sgb-cb");  // Query all gallery instances
        galleryElements.forEach((galleryElement, index) => {
            // Create a container div for each slider
            const sliderContainer = document.createElement("div");
            galleryElement.after(sliderContainer);

            // Render the Slider component into the newly created container
            ReactDOM.render(<Slider galleryIndex={index} />, sliderContainer);  // Pass an index or unique key if necessary
        });
    };

   render() {

        
        //const { postContent, inlineStyles } = this.state;

        let strActiveMenu = "main";
        let hideMainImage=false;

       return (

        

        <div className="App">  

        {/* Inline styles */}
        <style>{this.state.inlineStyles}</style>

            <Header activeMenu={strActiveMenu} hide={hideMainImage} className="hidden" hideSearch={true} hideSearchBox={true} />
            <main className={this.props.match.params.id} style={this.state.isLoaded?{}:{background:'#fff'}}>

            <link rel="stylesheet" href="/assets/bootstrap@5.1.3/css/bootstrap.min.css" />

            <link rel='stylesheet' id='wp-block-image-rtl-css' href='https://blog.afnu.co.il/wp-includes/blocks/image/style-rtl.min.css?ver=6.7.1' media='all' />

            
            <div id="vacations" style={{margin:'1em', maxWidth:'1366px',  margin:'0 auto', textAlign:'center' }}>
                <div style={{textAlign:'center', margin:'.5em'}}><h1 style={{textAlign:'center', fontWeight: 800, color: '#1f5981', marginTop: '1em'}}>חופשות מרובות יעדים</h1></div>

                
                <div className={this.state.isLoaded?'wave':'hidden'}>
                        <svg viewBox="0 0 1500 62" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minHeight:'40px'}}>
                            <path transform="scale(1)" fillRule="evenodd" clipRule="evenodd" d="M2748.4,38.1c-211.5-0.3-438.7,84.6-634.5-0.8c-190-82.9-394.4,85.4-609.5-0.7c-204.9-82.1-433,86.2-650.9-0.8
                    C659-41.9,412.2,122.7,235.7,35c-157-78-314.4,87.7-513-0.6C-444.1-39.8-635.1,34-814,33.7v89h3562.4V38.1z" fill="#eee"></path></svg>
                </div>
                <img src='/images/Iphone-spinner-2.gif' width='64' height='64' className={this.state.isLoaded?'hidden':''}></img>


                <div className="vacations_wrapper">
                    {this.state.posts.map(post =>
                    <div className="vacation" key={post.slug}  style={{border:'1px solid #ccc', margin:'2em 1em', borderRadius:'10px'}}>
                        <a href={''+post.slug} style={{textDecoration:'none', color: '#333'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height:'95px', position: 'absolute', width:'100%'}}>
                                <div style={{width: '100%', opacity: 0.7,  position: 'absolute', height: '66px', background: '#fff'}}></div>
                                <h1 style={{lineHeight: 1,  padding: '0 1em', textAlign: 'center',  color: '#124b71',  fontSize: '2em', position:'absolute', width: '90%', marginTop:'12px'}}>
                                        <span style={{fontWeight:'800', fontSize:'.7em'}} dangerouslySetInnerHTML={{__html: post.title.rendered}}></span></h1>
                            </div>

                            <div className="overlay" >
                            {<img src={post.jetpack_featured_media_url} style={{marginLeft:'auto', marginRight:'auto', borderRadius:'5px 5px 0 0', maxWidth:'100%'}}></img>}</div>
                            <div style={{textAlign:'right'}}>
                                
                                <div style={{display:'flex', fontWeight: 600, padding:'1em 1em 1.5em', color: '#333', justifyContent:'space-between', fontSize: '1.2em'}}>
                                    <div style={{color:'#555'}}>
                                        <h5 style={{fontWeight:'800', fontSize:'1.2rem', lineHeight:1}}>טיסות+מלונות</h5>
                                        <p style={{lineHeight:2, margin:0, fontWeight: 700}}>{post.nights} לילות</p>
                                        <p style={{lineHeight:1, margin:0}}>{post.from_date} עד {post.to_date}</p>
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        <p style={{color:'#124b71', fontWeight:800, fontSize:'1.7em', margin:0}}>${post.price}</p>
                                        <p style={{fontSize:'.8em', margin:0}}>לנוסע בהרכב</p>
                                        <p style={{fontSize:'.8em', margin:0}}>{post.passengers}</p>
                                    </div>
                                </div>
                                {/*<p dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}></p>*/}
                                
                                
                            </div>

                            
                        </a>
                    </div>
                    )}
                </div>
            </div>
            </main>
            <Footer />

            
            {/*<script id="simply-gallery-block-script-js-extra">{this.state.inlineJS1}</script>
            <script id="wp-block-template-skip-link-js-after" >{this.state.inlineJS2}</script>
            <script id="pgc-simply-gallery-plugin-lightbox-script-js-extra1" >{this.state.inlineJS3}</script>
            <script id="pgc-simply-gallery-plugin-lightbox-script-js-extra2" >{this.state.inlineJSDATA}</script>
            <script id="pgc-simply-gallery-plugin-lightbox-script-js-extra3" >{this.state.inlineJS4}</script>*/}

            

            <script src="https://blog.afnu.co.il/wp-content/plugins/simply-gallery-block/plugins/pgc_sgb_lightbox.min.js?ver=3.2.4.4" id="pgc-simply-gallery-plugin-lightbox-script-js" type="50c8a46d3093aaaf7ed1c6b9-text/javascript"></script>
            
            <Slider />
        </div>
       )
   }
}
export default Posts