import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./Slider.css";

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      activeIndex: 0,
      startX: 0,
      currentTranslate: 0,
      isDragging: false,
      container: null,
    };

    this.sliderRef = React.createRef();
    this.thumbnailTrackRef = React.createRef();
  }

  componentDidMount() {
    const { galleryIndex } = this.props;
    const galleryElements = document.querySelectorAll(".pgc-sgb-cb");
    const galleryElement = galleryElements[galleryIndex];

    if (galleryElement) {
      const galleryItems = galleryElement.querySelectorAll(".sgb-item");

      if (galleryItems.length > 0) {
        const images = Array.from(galleryItems).map((item) => ({
          src: item.querySelector("img").src,
          large: item.querySelector("img").src.split("?")[0], // Get large image
        }));

        this.setState({
          images,
          activeIndex: 0,
          container: galleryElement,
        });
      }
    }
  }

  handleDragStart = (e) => {
    const startX = e.touches ? e.touches[0].clientX : e.clientX;
    this.setState({ isDragging: true, startX, currentTranslate: 0 });
  };

  handleDragMove = (e) => {
    if (!this.state.isDragging) return;

    const currentX = e.touches ? e.touches[0].clientX : e.clientX;
    const currentTranslate = currentX - this.state.startX;

    this.setState({ currentTranslate });
  };

  handleDragEnd = () => {
    const { currentTranslate } = this.state;

    if (currentTranslate > 50) {
      this.handlePrev();
    } else if (currentTranslate < -50) {
      this.handleNext();
    }

    this.setState({ isDragging: false, currentTranslate: 0 });
  };

  handleNext = () => {
    this.setState(
      (prevState) => ({
        activeIndex: (prevState.activeIndex + 1) % prevState.images.length,
      }),
      this.updateThumbnailScroll
    );
  };

  handlePrev = () => {
    this.setState(
      (prevState) => ({
        activeIndex:
          (prevState.activeIndex - 1 + prevState.images.length) %
          prevState.images.length,
      }),
      this.updateThumbnailScroll
    );
  };

  updateThumbnailScroll = () => {
    const { activeIndex } = this.state;
    const thumbnailTrack = this.thumbnailTrackRef.current;

    if (thumbnailTrack) {
      const thumbnailWidth = thumbnailTrack.children[0].offsetWidth;
      const scrollTo = Math.max(
        0,
        thumbnailWidth/2 * activeIndex 
      );
      console.log(scrollTo)

      thumbnailTrack.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };

  renderSlider() {
    const { images, activeIndex, currentTranslate, isDragging } = this.state;

    if (images.length === 0) return null;

    const sliderTransform = {
      transform: `translateX(calc(-${activeIndex * 100}% + ${currentTranslate}px))`,
      transition: isDragging ? "none" : "transform 0.5s ease",
    };

    return (
      <div className="slider">
        {/* Large Image Carousel */}
        <div
          className="sgb-gallery-large"
          ref={this.sliderRef}
          onMouseDown={this.handleDragStart}
          onMouseMove={this.handleDragMove}
          onMouseUp={this.handleDragEnd}
          onMouseLeave={this.handleDragEnd}
          onTouchStart={this.handleDragStart}
          onTouchMove={this.handleDragMove}
          onTouchEnd={this.handleDragEnd}
        >
          {/* Navigation Buttons */}
          <button
            className="slider-btn slider-btn-left"
            onClick={this.handlePrev}
          >
            &#8249;
          </button>

          {/* Image Wrapper */}
          <div className="slider-track" style={sliderTransform}>
            {images.map((image, index) => (
              <div
                className="slider-image-container"
                key={index}
                style={{
                  backgroundImage: `url(${image.large})`,
                }}
              ></div>
            ))}
          </div>

          <button
            className="slider-btn slider-btn-right"
            onClick={this.handleNext}a
          >
            &#8250;
          </button>
        </div>

        {/* Thumbnails */}
        <div className="sgb-gallery-thumbnails">
          <div className="thumbnail-track" ref={this.thumbnailTrackRef}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={`Thumbnail ${index + 1}`}
                className={activeIndex === index ? "active" : ""}
                onClick={() => this.setState({ activeIndex: index }, this.updateThumbnailScroll)}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { container } = this.state;

    return container
      ? ReactDOM.createPortal(this.renderSlider(), container)
      : null;
  }
}

export default Slider;
////////////////////////