import React from 'react';
import configData from './config/config.json';
import './assets/css/App.css';
import Header from "./features/Layout/Header"
import Heading from "./features/Heading/index"
//import GlobalFilters from "./features/FiltersManager/GlobalFilters"
import FiltersManager from "./features/FiltersManager"
//import Intro from "./components/Homepage/Intro"
//import Media from "./components/Homepage/Media"
import Shortcuts from "./features/Shortcuts"
import Footer from "./features/Layout/Footer"
import Deck from "./components/Homepage/Deck"
//import Search from "./features/FiltersManager/Search"
import Cookies from 'universal-cookie';
import Banner from "./features/Banner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import Promotion from './features/Promotion';

//const API_ENDPOINT = 'http://afnu/api/v1/';
const API_ENDPOINT = configData.API_ENDPOINT;
const API_QUERY = "?";//"get/?lang=he";
const cookies = new Cookies();
let API_PREDEFINED_QUERY = "";
let API_OBJECT = "/search/packages/";
/*
let HideHeaderImg = false;

if (window.location.pathname.indexOf('.html')>0) {
  HideHeaderImg = true;
}
*/

class App extends React.Component {

  constructor(props) {
    super(props);
      
    // Load query string parameters or set default values
    let stars = new URLSearchParams(props.location.search).get("stars")?new URLSearchParams(props.location.search).get("stars").split(','):[];
    let meals = new URLSearchParams(props.location.search).get("meals")?new URLSearchParams(props.location.search).get("meals").split(','):[];
    let holidays = new URLSearchParams(props.location.search).get("holidays")?new URLSearchParams(props.location.search).get("holidays").split(','):[];
    let dates_type = new URLSearchParams(props.location.search).get("dates_type")?new URLSearchParams(props.location.search).get("dates_type"):'';
    let nights = new URLSearchParams(props.location.search).get("nights")?new URLSearchParams(props.location.search).get("nights").split(','):[];
    let from_date = new URLSearchParams(props.location.search).get("from_date")?new URLSearchParams(props.location.search).get("from_date"):'';
    let to_date = new URLSearchParams(props.location.search).get("to_date")?new URLSearchParams(props.location.search).get("to_date"):'';
    //let months = new URLSearchParams(props.location.search).get("months")?(new URLSearchParams(props.location.search).get("months")).split(','):(props.location.pathname=='/'&&from_date==''?['2023-07','2023-08']:[]);
    let months = new URLSearchParams(props.location.search).get("months")?(new URLSearchParams(props.location.search).get("months")).split(','):[];
    let sortby = new URLSearchParams(props.location.search).get("sortby")?new URLSearchParams(props.location.search).get("sortby"):'price-asc';
    let destinations = (new URLSearchParams(props.location.search).get("destinations")&&new URLSearchParams(props.location.search).get("destinations")!='')==true?new URLSearchParams(props.location.search).get("destinations").split(','):[];
    let country = new URLSearchParams(props.location.search).get("country")?new URLSearchParams(props.location.search).get("country"):'';
    let adults = new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults"):2;
    let teens = new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens"):0;
    let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children"):0;
    let infants = new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants"):0;
    let dow = new URLSearchParams(props.location.search).get("dow")?new URLSearchParams(props.location.search).get("dow").split(','):[];
    let airlines = new URLSearchParams(props.location.search).get("airlines")?new URLSearchParams(props.location.search).get("airlines").split(','):[];
    let waterpark = new URLSearchParams(props.location.search).get("waterpark")?true:false;
    let luxury = new URLSearchParams(props.location.search).get("luxury")?true:false;
    let casino = new URLSearchParams(props.location.search).get("casino")?true:false;
    let near_chabad = new URLSearchParams(props.location.search).get("chabad")?true:false;
    let spa = new URLSearchParams(props.location.search).get("spa")?true:false;

    let search = (props.location.pathname == '/search' || props.location.pathname == '/search/')?true:false;
    let active_shortcut = 'home';
    let heading_text = 'הדילים הכי זולים'
    

    // Override from_date, to_date, active_shortcut and heading_text with the proper data based on the URL
    switch( window.location.pathname ) {
      case '/summer-family/':
        from_date = '2025-08-01';
        to_date   = '2025-08-31';
        children  = 3;
        dates_type = 'range';
        active_shortcut = 'summer-family';
        heading_text = 'דילים למשפחות בחופש בגדול';
        document.title = 'דילים למשפחות בחופש הגדול - עפנו';
        break;
      case '/passover-vacation-deals/':
        from_date = '2025-04-04';
        to_date   = '2025-04-19';
        dates_type = 'range';
        active_shortcut = 'passover-vacation-deals';
        heading_text = 'דילים בחופש פסח';
        document.title = 'דילים בחופש פסח - עפנו';
        break;
      case '/passover/':
        from_date = '2025-04-12';
        to_date   = '2025-04-19';
        dates_type = 'range';
        active_shortcut = 'passover';
        heading_text = 'דילים בחול המועד פסח';
        document.title = 'דילים בפסח - עפנו';
        break;  
      case '/sukkot-vacation-deals/':
        from_date = '2025-10-01';
        to_date   = '2025-10-14';
        dates_type = 'range';
        active_shortcut = 'sukkot-vacation';
        heading_text = 'דילים בחופש סוכות';
        document.title = ' דילים בחופש סוכות - עפנו';
        break;
      case '/summer-vacation/':
        from_date = '2025-07-01';
        to_date   = '2025-08-28';
        dates_type = 'range';
        active_shortcut = 'summer-vacation';
        heading_text = 'דילים בחופש הגדול';
        document.title = 'דילים בחופש הגדול - עפנו';
        nights=[4,5,6,7];
        //children='2';
        break;
      case '/summer-deals/':
        from_date = '2025-05-01';
        to_date   = '2025-08-28';
        dates_type = 'range';
        active_shortcut = 'summer';
        heading_text = 'דילים בקיץ 2024';
        document.title = 'דילים בקיץ 2024 - עפנו';
        break;
      case '/holiday-season-deals/' || '/holiday-season-deals':
        from_date = '2025-09-21';
        to_date   = '2025-10-18';
        dates_type = 'range';
        active_shortcut = 'holiday-season-deals';
        heading_text = 'דילים בחגי תשרי';
        document.title = 'דילים בחגי תשרי 2025 - עפנו';
        //https://www.afnu.co.il/?dates_type=range&from_airport=TLV&sortby=price-asc&from_date=2024-10-1&to_date=2024-10-24&adults=2
        break;
      case '/xmas-deals/':
        from_date = '2024-11-28';
        to_date   = '2024-12-25';
        dates_type = 'range';
        active_shortcut = 'xmas';
        heading_text = 'דילים בתקופת הכריסמס';
        document.title = 'דילים בכריסמס - עפנו';
        break;
      case '/5-stars/':
        stars = [5];
        active_shortcut = '5stars';
        heading_text = 'דילים במלונות יוקרה';
        document.title = 'דילים במלונות יוקרה - עפנו';
        break;
      case '/weekends/':
        active_shortcut = 'weekends';
        heading_text = 'דילים בסופי שבוע';
        dow=['fri','sat']
        document.title = 'דילים לחול בסופי שבוע - עפנו';
        break;
      case '/waterparks/':
        active_shortcut = 'waterpark';
        heading_text = 'דילים במלונות עם פארק מים';
        waterpark = true;
        document.title = 'דילים במלונות עם פארק מים - עפנו';
        break;
      case '/luxury/':
          active_shortcut = 'luxury';
          heading_text = 'דילים במלונות יוקרה';
          luxury = true;
          document.title = 'דילים במלונות יוקרה';
          break;
      case '/casino/':
          active_shortcut = 'casino';
          heading_text = 'דילים במלונות עם קזינו';
          casino = true;
          document.title = 'דילים במלונות עם קזינו';
          break;
      case '/near-chabad/':
        active_shortcut = 'chabad';
        heading_text = 'דילים במלונות ליד בית חבד';
        near_chabad = true;
        document.title = 'דילים במלונות ליד בית חבד';
        break;
      case '/spa/':
        active_shortcut = 'spa';
        heading_text = 'דילים במלונות ספא';
        spa = true;
        document.title = 'דילים למלונות עם ספא';
        break;
      case '/israeli/':
        active_shortcut = 'israeli';
        heading_text = 'דילים עם חברות תעופה ישראליות';
        airlines = 'LY,IZ,6H';
        document.title = 'דילים עם חברות תעופה ישראליות';
        break;
      case '/last-minute/':
        active_shortcut = 'last-minute';
        heading_text = 'דילים ברגע האחרון';
        document.title = 'דילים ברגע האחרון - עפנו';
        const currentDate = new Date()
        from_date = currentDate.getFullYear() + '-' + (currentDate.getMonth()*1+1) + '-' + currentDate.getDate(); 
        const endDate = new Date(currentDate.setDate(currentDate.getDate()+14)); // 10
        to_date   = endDate.getFullYear() + '-' + (endDate.getMonth()*1+1) + '-' + endDate.getDate();  // date in 10 day
        dates_type = 'range';
        break;
      case '/one-week/':
        active_shortcut = 'one-week';
        heading_text = 'דילים עד 7 לילות';
        document.title = 'דילים לשבוע בחול - עפנו';
        nights = [6,7];
        break;
      case '/all-inclusive-deals/':
        active_shortcut = 'all-inclusive';
        heading_text = 'חופשות הכל-כלול';
        document.title = 'דילים עם מלונות הכל כלול (טיסה+מלון) - עפנו';
        meals=['AI'];
        //months=['2023-07','2023-08'];
        break;      
      case '/early-birds/':
        active_shortcut = 'earlybirds';
        heading_text = 'מקדימים וחוסכים';
        document.title = 'דילים זולים מזמינים מוקדם - עפנו';
        from_date = '2025-05-01'
        to_date   = '2025-09-01'
        dates_type = 'range';
        //months=['2023-07','2023-08'];
        break;        
      case '/best-deals/':
        active_shortcut = 'best';
        //heading_text = 'מלונות הכל-כלול';
        document.title = 'הדילים הכי זולים - עפנו';
        //meals=['AI'];
        break;        
    }

    if (window.location.hash=='#best-deals') {
      active_shortcut='best'
    }


    this.state = {
      'heading_text': heading_text,
      'active_shortcut': active_shortcut,
      'destinations' : [],
      'country': country,
      'carousel_items': [],
      'isLoading'   : true,
      'error'       : null,
      'show_destinations_popup': false,
      'filters' : {
        'window': {
          'visible': search,
          'active_tab': 'search',
          'refresh_count': 0,
          'step': '0'
        },
        'origin': {
          'iata': {
            'TLV': true,
            'ETM': false
          },
        },
        'from_airport'  : 'TLV',
        'stars'         : stars,
        'meals'         : meals,
        'holidays'      : holidays,
        'dates_type'    : dates_type,
        'nights'        : nights,
        'from_date'     : from_date,
        'to_date'       : to_date,
        'limit'         : 10,
        'destination_name': 'כל היעדים',
        'destinations'  : destinations,
        'country'       : country,
        'sortby'        : sortby,
        'months'        : months,
        'adults'        : adults,
        'teens'         : teens,
        'children'      : children,
        'infants'       : infants,
        'dow'           : dow,
        'airlines'      : airlines,
        'highlights'    : {
          'waterpark'     : waterpark,
          'luxury'        : luxury,
          'near_chabad'   : near_chabad,
          'spa'   : spa,
          'casino'        : casino
        }
      },
      cities: [],
      heading_key: 0
    }
    
    // binding
    this.getFilters = this.getFilters.bind(this); 
    this.fetchDestinations = this.fetchDestinations.bind(this);
    this.updateDestinations = this.updateDestinations.bind(this); 
    this.showSearchWindow = this.showSearchWindow.bind(this);
    this.closeSearchWindow = this.closeSearchWindow.bind(this);
    this.handleClose = this.handleClose.bind(this); 
    //this.openSearch = this.openSearch.bind(this);
    this.showDestinationSelectionPopup = this.showDestinationSelectionPopup.bind(this);
    this.handleCloseSearchWindow = this.handleCloseSearchWindow.bind(this);
    this.gotoStep = this.gotoStep.bind(this); 
    

    //https://stackoverflow.com/questions/54245395/how-can-i-scroll-to-a-div-onclick-using-react
    this.myDivToFocus = React.createRef()
    this.resultsPaneRef = React.createRef()
  }

  componentDidMount() {
    //console.log('componentDidMount')
    this.refetchData()

    
  }

  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  refetchData(updateURL=false) {
    //console.log('Refetch1')
    //console.log(this.state.filters)
    
    // Focus results section
    if (updateURL) this.resultsPaneRef.current.focus()
    
    this.query_options = new Map();
    this.query_options.set('stars',this.state.filters.stars);
    this.query_options.set('meals',this.state.filters.meals);
    this.query_options.set('holidays',this.state.filters.holidays);
    this.query_options.set('dates_type',this.state.filters.dates_type);
    this.query_options.set('nights',this.state.filters.nights);
    this.query_options.set('from_airport',this.state.filters.from_airport);
    this.query_options.set('months',this.state.filters.months);
    this.query_options.set('sortby',this.state.filters.sortby);
    this.query_options.set('from_date',this.state.filters.from_date);
    this.query_options.set('to_date',this.state.filters.to_date);
    this.query_options.set('adults',this.state.filters.adults);
    this.query_options.set('teens',this.state.filters.teens);
    this.query_options.set('children',this.state.filters.children);
    this.query_options.set('infants',this.state.filters.infants);
    this.query_options.set('dow',this.state.filters?.dow);
    this.query_options.set('airlines',this.state.filters?.airlines);
    this.query_options.set('destinations',this.state.filters?.destinations);
    this.query_options.set('country',this.state.filters?.country);
    this.query_options.set('waterpark',this.state.filters?.highlights?.waterpark);
    this.query_options.set('luxury',this.state.filters?.highlights?.luxury);
    this.query_options.set('casino',this.state.filters?.highlights?.casino);
    this.query_options.set('chabad',this.state.filters?.highlights?.near_chabad);
    this.query_options.set('spa',this.state.filters?.highlights?.spa);

    //let querystring = "";
    //this.query_options.forEach((value,key) => querystring+=`&${key}=${value}`) ;

    let querystring_array = [];

    // Remove empty keys
    // Sort values (if array)
    this.query_options.forEach((value,key) => {
      if (value && value!='') {
          if (Array.isArray(value))
            value = value.sort();

          querystring_array.push(`${key}=${value}`)  
      }
    });

    // Sort result array by keys & rebuild querystring
    const querystring=querystring_array.sort((a,b)=>a-b).join('&');
    
    const url =  API_ENDPOINT + API_OBJECT + API_QUERY + querystring + API_PREDEFINED_QUERY

    // Should the URL indicate the filters 
    if (updateURL)
      this.props.history.push("/" + API_QUERY + querystring + API_PREDEFINED_QUERY)

    return (
      fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
        }
      })
      .then(data =>  {

        let cities = [];

        // Create an array with carousel's destinations - the first two items will be the cheapest destinations that we've got
        const carousel_cities = [];
        if (data.destinations && data.destinations.length>=2) {
          carousel_cities.push(data.destinations[0].destination.city_id);
          carousel_cities.push(data.destinations[1].destination.city_id);
        }
        carousel_cities.push(1); // Amsterdam
        carousel_cities.push(9); // Paris
        carousel_cities.push(42); // Berlin
        carousel_cities.push(4); // Milan
        carousel_cities.push(23); // London
        carousel_cities.push(48); // Venice
        
        let carousel_items = [];

        // Initialize the destinations array only on the first load (so it won't reload after filters were invoked and we'll lose some cities)
        if (this.state.cities.length == 0) 
          data.destinations.map(dest => {
            cities.push(dest.destination);
          })
        else
          cities = this.state.cities;
       
        // If this city should be in carousel
        data.destinations.map(dest => {
          if (carousel_cities.includes(dest.destination.city_id)) {
            const highlight = JSON.parse(JSON.stringify(dest));
            // pull only the first deal
            highlight.deals = highlight.deals[0];
            carousel_items.push(highlight)
          }
        })

        //console.log(this.state.filters)

        this.setState({
          'destinations': this.state.filters?.sortby==='price-desc'?
            data.destinations.sort((b,a) => a.deals[0]?.price-b.deals[0]?.price):
            data.destinations.sort((a,b) => a.deals[0]?.price-b.deals[0]?.price), 
          'carousel_items': carousel_items,
          'isLoading': false, 
          filters: {...this.state.filters },
          'cities':  cities
       });

       // Focus on results page after deals finished loading
       //if (updateURL) this.resultsPaneRef.current.focus()
        
      })
      .catch(error=>this.setState(
          {
            error, 
              'isLoading': false,
              filters: {...this.state.filters },
          }
      )
      )
    );  
  }


      
  async fetchDestinations() {

    console.log(`Fetch destinations`)
    const API_ENDPOINT = configData.API_ENDPOINT;
    const url =  API_ENDPOINT + '/search/destinations/?lang=he';

    const results = await fetch(url)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
          }
        })

    this.setState(prevState=>(
      {
        ...prevState,
        'all_destinations': results
      }
    ))

}


  getFilters(filtersVal, updateURL=false) {

    // JAVASCRIPT ERROR: Cannot convert object to primitive value => which means it fails to convert the QS object to string 
    // BUGFIX: not filtering after filtering and then  refreshing the page and then selecting destination 
    filtersVal.QS = null;

     // If user selected only 1 destination, go directly to deals page of this destination
//     alert(filtersVal.destinations)
    const queryString = Object.keys(filtersVal).map(key => key + '=' + filtersVal[key]).join('&');
    if (filtersVal.destinations && filtersVal.destinations.length==1) {
        if ((filtersVal.months && filtersVal.months.length == 1) || (filtersVal.from_date.length > 0 && filtersVal.to_date.length > 0)) {
            // If we've searched for 1 destination + 1 month => skip the months page and go directly to the deals index page
            // OR we've selected 1 destination + dates range => skip the months page and go directly to the deals index page
            window.location = '/דילים/מותאמים-אישית/?' + queryString.replace('months=','month=')       
        } else {
            window.location = '/דילים/?' + queryString
        }
     }

    if  (filtersVal.destination!=null && filtersVal.destination!='null' && filtersVal.destination!=null && filtersVal.destination!='' && filtersVal.destination!=null && filtersVal.destination!='all') {

      console.log(`Multiple destination selected: ${filtersVal.destination}`)
      //console.log(filtersVal)
      //console.log(queryString)
      
      if (filtersVal?.when=='dates' || filtersVal?.when=='months') {
        // if destination changed but also specific dates / months selected go directly to the month results page
        window.location = '/' + filtersVal.destination_name  + '/'+filtersVal.when_caption+'/?' + queryString
      } else {
        window.location = '/' + filtersVal.destination_name  + '?' + queryString        
      }

      return
    }
  
    //.current is verification that your element has rendered
    if(this.myDivToFocus.current){
      this.myDivToFocus.current.scrollIntoView({ 
         behavior: "smooth", 
         block: "nearest"
      });
      this.myDivToFocus.current.focus({preventScroll: true});
      
    }

    console.log('Saving filters...')
    //console.log(filtersVal)
    

    this.setState(prevState=>(
      {
        ...prevState,
        'destinations': [] ,
        'isLoading': true, 
        'heading_text': 'תוצאות מותאמות אישית',
        'filters': filtersVal,
        
      }),
      () => this.refetchData(updateURL)
    );    

    //document.getElementById('results_pane').current.focus();
  }


  /**
   * 
   * @param {*} step 
   * @param {*} next_step_close 
   */
  showSearchWindow(step='0', next_step_close=false) {
    console.log('Show search wizard ')

    this.setState((prevState) => (
      {
          ...prevState,
          filters: {
              ...prevState.filters,  
              'window': {
                  ...prevState.filters.windows,
                  'visible': true,
                  'active_tab': 'search',
                  'step': step,
                  'next_step_close': next_step_close
              }
          }            
      }
  ));
}

closeSearchWindow() {
  this.setState((prevState) => (
    {
        ...prevState,
        filters: {
            ...prevState.filters,  
            'window': {
                ...prevState.filters.windows,
                'visible': false,
            }
        }            
    }
));
}

  /*openSearch(event) {

    this.refreshChildState()
    
    console.log('Show search wizard ')
    this.setState(prevState=> ({
      ...prevState,
      //'destinations': this.state.destinations,
      //'isLoading': false, 
      filters: {
        ...prevState.filters,
        'window': {
          ...prevState.filters.window,
          'visible': true,
          //'refresh_count': this.state.filters.window.refresh_count?this.state.filters.window.refresh_count++:0,
        },
       }
   }));
  }*/

  handleClose() {
    //alert('closing')

    /*cookies.set('welcomed',1,{ 
      path: '/' ,
      maxAge: 86400 * 120 // 120 days
    })*/

    cookies.set('welcomed','1',{ 
      path: '/' ,
      maxAge: 86400 * 120 // 120 days
    })
    
  }
    
  showDestinationSelectionPopup() {

    this.setState(prevState=>{
      return {
        ...prevState,
        'show_destinations_popup': true,
        'heading_key': ++prevState.heading_key
      }
    })
  }

  handleClose() {
    
    this.setState({
      'destinations': this.state.destinations, 
      'isLoading': false, 
      filters: {
        ...this.state.filters,
        'window': {
          ...this.state.filters.window,
          visible: false,
        }
      },
    })
  }
    

  handleCloseSearchWindow() {
    console.log('handleCloseSearchWindow')
    this.setState((prevState)=>({
        ...prevState,
        filters: {
          ...prevState.filters,
          window: {
            ...prevState.filters.window,
            visible: false
          }
        }
      })
    )
  }

  /**
   * Once we've fetched the destinations list, update the state so we can use it in other places
   * @param {*} dest 
   */
  updateDestinations(cities) {

      this.setState(prevState=>(
        {
          ...prevState,
          'cities': cities,
        }
      ),
      () => {
        //console.log(dest)
        //console.log(this.state)
      }
    )
  }
  
  /**
   * Invoked by clcking the search icon in the top navigation bar
   */
  /*showSearchWindow(show) {
    
    this.setState(prevState=>(
      {
        ...prevState,
        filters: {
          ...prevState.filters,
          window: {
            ...prevState.filters.window,
            visible: show
          }
        }
      }
    ))
  }*/

 
  /**
   * Function will check if it is weekend in Israel (Friday/Sat)
   * @param {*} date 
   * @returns 
   */
  isWeekend(date = new Date()) {

      //return false; // disabled

      const d = new Date();
      const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      const offset = 3; // Use UTC+3 time = Israel's time
      const nd = new Date(utc + (3600000*offset));
      
      return nd.getDay() === 5 || nd.getDay() === 6;
  }

  gotoStep = (e, step, next_step_close=false) => {
    console.log('gotoStep', step)
    e.stopPropagation()

    // If we've click the step 1 button but it's empty jumpt to step 2
    if (step=='2') {
        if (this.state.filters?.dates_type==='exact'|| (this.state.filters?.holidays?.length>0 && !this.state.filters.holidays.find(holiday=>holiday.includes('school-summer-vacation'))) )
            step='3';
    }

    this.setState((prevState) => (
        {
           ...prevState,
            filters: {
                ...prevState.filters,  
                'window': {
                    ...prevState.filters.window,
                    'step': step+'',
                    'visible': true,//prevState.filters?.window?.visible?prevState.filters?.window?.visible:false,
                    'next_step_close': next_step_close
                    //'active_tab': 'search'
                }
            }            
        }
    ),()=>{
      console.log(this.state.filters)
    })
}

hideFloatingTopBanner(e) {
  this.setState(prevState=>({
   ...prevState,
    'floating_top_banner': {
     ...prevState.floating_top_banner,
      visible: 'no'
    }
  }))
  console.log('hideFloatingTopBanner')
  cookies.set('welcomed','1',{
    path: '/' ,
    maxAge: 60 * 60 * 24 * 45 // 45 days
  })

}

hidePopup(e) {
  this.setState(prevState=>({
   ...prevState,
    'popup_banner': {
     ...prevState.popup_banner,
      visible: 'no'
    }
  }))
  console.log('hide popup_banner')
  cookies.set('popup','1',{
    path: '/' ,
    maxAge: 86400 / 24 // 1 hour
  })

}

  render() {

    // conditional rendreing ahead
    const {error} = this.state;

    if (error) {
      return <p>{error.message}</p>;
    }

    let strActiveMenu = "main";
    let hideMainImage = "";
    if (window.location.pathname==="/last-minute") {
      
      //strHeading = "טיסה + מלון ברגע האחרון";
      strActiveMenu = "last-minute";
      hideMainImage = true;
    }


      //let hideHomepageIntro = this.props?.location?.search && this.props?.location?.search!=null;
      let hideHomepageIntro = (
        !new URLSearchParams(this.props.location.search).get("intro") && (
        new URLSearchParams(this.props.location.search).get("min_nights") ||
        new URLSearchParams(this.props.location.search).get("max_nights") ||
        new URLSearchParams(this.props.location.search).get("nights") ||
        new URLSearchParams(this.props.location.search).get("stars") ||
        new URLSearchParams(this.props.location.search).get("min_stars") || 
        new URLSearchParams(this.props.location.search).get("max_stars") || 
        new URLSearchParams(this.props.location.search).get("min_price") || 
        new URLSearchParams(this.props.location.search).get("max_price") || 
        new URLSearchParams(this.props.location.search).get("when") || 
        new URLSearchParams(this.props.location.search).get("from_date") || 
        new URLSearchParams(this.props.location.search).get("to_date") || 
        new URLSearchParams(this.props.location.search).get("dates") || 
        new URLSearchParams(this.props.location.search).get("months") || 
        new URLSearchParams(this.props.location.search).get("months_weekends") || 
        new URLSearchParams(this.props.location.search).get("sortby") || 
        new URLSearchParams(this.props.location.search).get("waterpark") || 
        new URLSearchParams(this.props.location.search).get("luxury") || 
        new URLSearchParams(this.props.location.search).get("casino") || 
        new URLSearchParams(this.props.location.search).get("chabad") || 
        new URLSearchParams(this.props.location.search).get("destination")) 
        
      );

      let searchResults = false;
      if (hideHomepageIntro) 
          searchResults = true;

      const urlParams = new URLSearchParams(window.location.search);
      const showSpaPopup = urlParams.get('popup')!=null ;
      
      // ENABLE / DISABLE floating top banner only once
      const boolShowTopBanner = false;
      return (
      <>
        {boolShowTopBanner?(
            <div id="floating_top_banner" className="floating_top_banner"  
                style={
                    {
                    display: (!this.state?.floating_top_banner?.visible || this.state?.floating_top_banner?.visible=='yes') && !cookies.get('welcomed')?'flex':'none',
                    justifyContent:'space-between', 
                    alignItems:'top', 
                    width: '100%',
                    background: '#f4d956', 
                    fontWeight: 800, 
                    fontSize: '18px',
                    color: '#333', 
                    position: 'fixed',
                    top: 0,
                    zIndex: 1000, 
                    height: '80px',
                    padding: '.5em'
                    }
                }>
                    <div id="closeButton">
                        <button tabIndex="1" style={{border:'1px solid #aaa', background:'none'}} title="הסתר מודעה" onClick={e=>{this.hideFloatingTopBanner(e)}} >
                            <FontAwesomeIcon icon={faTimes} size="1x" style={{color:'#000', cursor:'pointer'}}/>
                        </button>
                    </div>
                    <div style={{width:'100%'}}>
                    <p style={{ lineHeight: 1.25, marginBottom:0}}><span style={{fontWeight:800,color:'#bc2a2a'}}>חדש בעפנו!</span> ביטוח ביטול חופשה מכל סיבה  <br />עד 6 שעות לפני ההמראה...</p>
                    <p style={{marginBottom:0, marginTop: '.5em', fontSize:'.7em', fontWeight: 400}}>פרטים נוספים בדף ההזמנה</p>
                    </div>
            </div>
        ):null}
        
        <Promotion campaign='spa' show={showSpaPopup} />
        
        <div className="App" style={{marginTop:!this.state?.floating_top_banner && boolShowTopBanner && !cookies.get('welcomed')?'80px':'0',display:'block'}}>
                
          <Header 
            
            activeMenu={strActiveMenu} 
            hide={hideMainImage} 
            className="hidden" 
            openSearchWindow={this.showSearchWindow} 
            location={this.props?.location} 
            hidden={this.props.hidden} 
            hideHomepageIntro={hideHomepageIntro} 
            filters={this.state?.filters}
            style={{marginTop:!this.state?.floating_top_banner && !cookies.get('welcomed')?'80px':'0px'}} />

          <main>
              {/*<Carousel data={this.state.carousel_items} isLoading={this.state.isLoading}  hidden={hideHomepageIntro} />*/}
              <Shortcuts openSearchClicked={this.showSearchWindow} hidden={!searchResults && hideHomepageIntro} hideHeader={searchResults} filters={this.state.filters} active={this.state.active_shortcut} searchResults={searchResults}  />
              <a name="deals"></a>
              <div className='wave'>
                <svg viewBox="0 0 1500 62" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minHeight:'40px'}}>
                  <path transform="scale(1)" fillRule="evenodd" clipRule="evenodd" d="M2748.4,38.1c-211.5-0.3-438.7,84.6-634.5-0.8c-190-82.9-394.4,85.4-609.5-0.7c-204.9-82.1-433,86.2-650.9-0.8
          C659-41.9,412.2,122.7,235.7,35c-157-78-314.4,87.7-513-0.6C-444.1-39.8-635.1,34-814,33.7v89h3562.4V38.1z" fill="#eee"></path></svg>
              </div>
                
              <Banner campaign='rosh25' hide={true || !this.isWeekend()}  />
              {/*<div>
                <img style={{margin:'0 auto 1.5em', width:'95%', maxWidth:'450px'}} src={'/images/banners/israeli-airlines.jpg'} alt="הודעה: אצלנו נותנים עדיפות לחברות תעופה שממשיכות לטוס לישראל למרות המצב הבטחוני"  />
              </div>*/}
              
              
                  
            <Heading key={'header'+this.state?.heading_key} sendDataToCaller={this.updateDestinations} show_popup={this.state?.show_destinations_popup} destinations={this.state.cities} destination={this.state?.destination} destination_id={this.state?.destination?.city_id} destination_name={this.state?.destination?.city_name} filters={this.state.filters} text={this.state.heading_text}  />
            <div ref={this.resultsPaneRef} tabIndex="0">
              <FiltersManager 
                  filters={this.state.filters} 
                  openSearch={this.state.filters?.window?.visible?this.state.filters?.window?.visible:false} 
                  destinations={this.state?.all_destinations} 
                  sendDataToCaller={this.getFilters} 
                  showSearchWindow={this.showSearchWindow} 
                  closeSearchWindow={this.closeSearchWindow}
                  gotoStep={this.gotoStep}
                  location={this.props.location} 
                  month_name={this?.month_name} 
                  months={this.months} 
                  motn={this.month}
                  fetchDestinations={this.fetchDestinations}
                  year={this.year}></FiltersManager>

              <div className={'preloader ' + (this.state.isLoading?' show-item':' hide-item')} tabIndex="0" autoFocus={true}>
                <FontAwesomeIcon icon={faSpinner} spin size="3x" style={{margin:'.5em auto'}} title="טוען תוצאות... אנא המתן" />
              </div>
              
              <div>
                <Deck  autoFocus={true} data={this.state.destinations} key="deck1" tabIndex="0" cities={this.state?.cities} ready={!this.state.isLoading} type="deals" meals={this.state?.filters?.meals} nights={this.state?.filters?.nights}  stars={this.state?.filters?.stars} waterpark={this.state?.filters?.highlights?.waterpark} luxury={this.state?.filters?.highlights?.luxury} casino={this.state?.filters?.highlights?.casino} near_chabad={this.state?.filters?.highlights?.near_chabad} spa={this.state?.filters?.highlights?.spa} from_date={this.state?.filters?.from_date} to_date={this.state?.filters?.to_date} dates_type={this.state?.filters?.dates_type} months={this.state?.filters?.months} adults={this.state?.filters?.adults} teens={this.state?.filters?.teens} children={this.state?.filters?.children} infants={this.state?.filters?.infants} dow={this.state?.filters?.dow} airlines={this.state?.filters?.airlines} />
              </div>
            </div>
            </main>
            <Footer hide={(this.state.isLoading?true:false)} />
          </div>
      </>
      );
    }
  }

export default App;