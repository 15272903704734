import React from "react"
const logo_image_white = require('../../assets/images/logo-white.png'); // with require

class MainNavigationBar extends React.Component {
    nav = React.createRef();
    backButtonVisible = "hide";
    MenuButtonStatus = ""
    menuContentVisible = "hide"

    constructor (props) {
        super(props)
        this.state = {
            'open': false,
            'menuVisible' : false
        }

        if (window.location.pathname.indexOf('/deal/')>=0 || window.location.pathname.indexOf('/flight/')>=0) {
            this.backButtonVisible = "";
            this.MenuButtonStatus = "hide";
        } else {
            this.menuOpenVisible = "";
            this.MenuButtonStatus = "";
        }        

        // binding
        this.handleLogoClick = this.handleLogoClick.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this)
        this.hideMenu  = this.hideMenu.bind(this);
        this.trackMenuClick = this.trackMenuClick.bind(this)
        

    }

    trackMenuClick = (e, item_name) => {
        window.dataLayer = window.dataLayer || [];
        const event_name = 'MenuClick';
        console.log('GTM event: ' + event_name)
        window.dataLayer.push({
            event: event_name,
            name: item_name,
            'pageUrl': window.location,
            'pageTitle' : window.title,
        });
    }

    handleLogoClick = () => {
        window.dataLayer = window.dataLayer || [];
        const event_name = 'LogoClick';
        console.log('GTM event: ' + event_name)
        window.dataLayer.push({
            event: event_name,
            'pageUrl': window.location,
            'pageTitle' : window.title,
        });
    }

    hideMenu = (event) => {
        event.preventDefault()
        this.setState((prevState)=>({
            ...prevState,
            menuVisible: false
        }))
    }

    handleMenuButtonClick = (e) => {
        e.preventDefault()
        this.setState( (prevState) => { 
            return {'menuVisible':(!prevState.menuVisible)}
        });

        
        window.dataLayer = window.dataLayer || [];
        const event_name = 'MenuButtonClick';
        console.log('GTM event: ' + event_name)
        window.dataLayer.push({
            event: event_name,
            'pageUrl': window.location,
            'pageTitle' : window.title,
        });

    }

    handleMenuOpen = () => {
        this.setState({
            'menu-button-open' : false,
        })

    }

    handleMenuClose = () => {
        
    }

    handleSearch = (e) => {
        e.preventDefault(e)
        if (this.props?.hideSearch) {
            window.location = '/search'
        }
        this.props.openSearchWindow();
    }

    handleShare = () => {
        if (navigator.share) {
            navigator.share({
              title: 'עפנו - חופשות סודיות',
              text: 'עפנו - חופשות סודיות',
              url: window.location.href,
            })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
        } else {
            //
        }

    }

    handleBack = (e) => {
        e.preventDefault()
        //console.log('clk');
        window.history.back();
    }

    render(){
        return (
            <>
                <div className={'menu-content ' + (this.state.menuVisible?'':'hide')}>
                    <ul>
                        <li className="first-item">
                            התפריט
                            {/*<a href="/"><i className="fa fa-search"></i></a>
                            <i className="fa fa-share-alt" onClick={this.handleShare}></i>
                            <a href="/contact.html"><i className="fa fa-envelope"></i></a>*/}
                        </li>
                        {/*<li className="mobile-only"><a href="/">דילים לחו"ל - חבילות טיסה+מלון </a></li>*/}
                        
                        {/*<li className="mobile-only"><a href="/last-minute"> דילים ברגע האחרון</a></li>*/}
                        <li><a href="/" title="בחזרה לדף הבית" tabIndex="1"><i className="fa fa-home fa-1x" /> דף הבית</a></li>
                        <li><a href="/search" title="חיפוש דילים" tabIndex="1"><i className="fa fa-search fa-1x" /> חיפוש דילים</a></li>
                        <li><a href="/article/about" title="אודות אתר עפנו" tabIndex="1"><i className="fa fa-graduation-cap fa-1x" /> אודות עפנו</a></li>
                        {/*<li><a href="/summer-deals/" style={{display: 'flex', justifyContent: 'right'}}><FontAwesomeIcon icon={faSun} style={{color: 'yellow', fontSize: '1.3em',  margin: '0 0 0 0.3em'}} /> חופשות קיץ - מקדימים וחוסכים!</a></li>*/}
                        <li><a href="/article/media" title="דיברנו עלינו בתקשורת" tabIndex="1"><i className="fa fa-bullhorn fa-1x" /> דיברו עלינו בתקשורת</a></li>
                        {/*<li><a href="/members/"><i className="fa fa-star fa-1x" /> מועדון VIP</a></li>*/}
                        <li><a href="/article/faq" title="שאלות ותשובות" tabIndex="1"><i className="fa fa-question fa-1x" /> שאלות ותשובות</a></li>
                        <li><a href="/article/contact" title="שירות לקוחות" tabIndex="1"><i className="fa fa-phone fa-1x" /> שירות לקוחות</a></li>                        

                    </ul>
                </div>
                
                <div id="headerNav" className={'headerNav ' + this.headerStyle} ref={this.nav}>
                    <div className="nav-sections">
            
                        <div className="right-nav">

                            <a href="#" onClick={e=>{this.handleBack(e)}} title="בחזרה לדף הקודם" className={this.backButtonVisible=='hide'?'hidden':''}>
                                <div className={"back " + this.backButtonVisible}>
                                <i className="fa fa-chevron-right fa-1x" /></div></a>

                            <a href="#" onClick={e=>{this.handleMenuButtonClick(e)}} title={this.state.menuVisible?'סגור תפריט':'פתח תפריט'} tabIndex="1">
                                <div className={"menu-button " +  this.MenuButtonStatus + " "  } >
                                <i className={this.state.menuVisible?'fa fa-times fa-1x':'fa fa-bars fa-1x'} /></div></a>
                            

                            <div className='logo' onFocus={e=>{this.hideMenu(e)}}>
                                <a href="/" onClick={e=>{this.handleLogoClick(e)}} title="עפנו - בחזרה לדף הבית" tabIndex="2">
                                    <div id="logo">
                                        <img src={logo_image_white} alt="עפנו - לוגו"></img></div>
                                </a>
                            </div>
                        </div>
                        
                        <div className="left-nav">
                            <a onClick={e=>{this.handleSearch(e)}} title="חיפוש דילים"><div className="search" tabIndex="2">
                                <i className="fa fa-search" /></div></a>
                        </div>
                    </div>
                
                    <nav className={"mobile_navigation " }>
                            <ul>
                                <li className={"menu_col1 " + this.props.activeMenu==='main'?'active':''}>
                                    <a href="/" onClick={e=>{this.trackMenuClick(e, 'homepage')}} title="הדילים הזולים שלנו" tabIndex="3">
                                        <i className="fa fa-plane fa-2x" />
                                        דילים<br />
                                    זולים</a></li>                            
                                <li className={"menu_col2 " + this.props.activeMenu==='weekends'?'active':''}>                                
                                    <a href="/weekends/"  onClick={e=>{this.trackMenuClick(e, 'weekends')}} title="דילים בסופי שבוע" tabIndex="3">
                                        <i className="fa fa-calendar fa-2x" />
                                        סופי<br />
                                        שבוע</a></li>
                                <li className={"menu_col3 " + this.props.activeMenu==='passover-vacation-deals'?'active':''}>                                
                                    <a href="/passover-vacation-deals/"  onClick={e=>{this.trackMenuClick(e, 'passover-vacation-deals')}} title="דילים בחופש בפסח" tabIndex="3">
                                        <i className="fa fa-calendar fa-2x" />
                                        דילים בחופש פסח</a></li>
                                {/*
                                <li className={"menu_col3 " + this.props.activeMenu==='holiday-season-deals'?'active':''}>                                
                                    <a href="/holiday-season-deals/"  onClick={e=>{this.trackMenuClick(e, 'holiday-season-deals')}} title="דילים בחגי תשרי" tabIndex="3">
                                        <i className="fa fa-calendar fa-2x" />
                                        דילים בחגי תשרי</a></li>
                                */}
                                {/*<li className={'menu_col5 vip ' + (this.props.activeMenu==='members'?'active':'')}>
                                    <a href="/members/"  onClick={e=>{this.trackMenuClick(e, 'vip')}}>
                                        <img src="/images/icons/vip.svg" alt="VIP" className="vip_icon" width="50" height="25"></img>
                                        מועדון<br />
                                        <span className="spn_vip">VIP</span>
                                </a></li>*/}
                            </ul>
                    </nav>
                    <div className="wrapper">
                        <div id="sidemenu_toggle" className="sidemenu_btn">
                            <span></span> <span></span> <span></span>
                        </div>
                        
                        <nav className="desktop_navigation">
                            <ul>
                                <li className={this.props.activeMenu==='main'?'active':''}>
                                    <a href="/best-deals/"  onClick={e=>{this.trackMenuClick(e, 'homepage')}} title="הדילים הכי זולים שלנו">הדילים הכי זולים </a></li>
                                <li className={this.props.activeMenu==='weekends'?'active':''}>                                
                                    <a href="/weekends/"  onClick={e=>{this.trackMenuClick(e, 'weekends')}} title="דילים בסופי שבוע">סופי שבוע</a></li>
                                <li className={this.props.activeMenu==='passover-vacation-deals'?'active':''}>
                                    <a href="/passover-vacation-deals/" onClick={e=>{this.trackMenuClick(e, 'passover-vacation-deals')}} title="דילים בחופש פסח">דילים בחופש פסח</a></li>
                                {/*<li className={this.props.activeMenu==='summer-deals'?'active':''}>
                                    <a href="/holiday-season-deals/" onClick={e=>{this.trackMenuClick(e, 'holiday-season-deals')}} title="דילים בחגי תשרי">חגי תשרי </a></li>*/}
                                {/*<li className={'members ' + (this.props.activeMenu==='vip'?'active':'')}>
                                    <a href="/members/" onClick={e=>{this.trackMenuClick(e, 'vip')}}>מועדון חברים VIP</a></li>                                */}
                            </ul>
                        </nav>
                        

                        <div id="connect">
                        </div>
                    </div>

                    
                    <div className="overlay"></div>
                </div>
            </>
        )

    }
}
export default MainNavigationBar