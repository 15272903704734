import React, { Component } from 'react'
import axios from 'axios';
import Header from "../../features/Layout/Header"
import Heading from "../../features/Heading/index"
import Footer from "../../features/Layout/Footer"
import './Post.css'
import "./Slider.css";
import Slider from "./Slider";
import ReactDOM from "react-dom";
import { Form, Input, Button, Select, DatePicker, Divider } from 'antd';
//import 'antd/dist/antd.css';
import './Form.css';





export class BookingForm extends Component {
   state = {
       posts: [],
       isLoaded: false
   }

   from_date = null;
   vacation_id = null;
   to_date = null;
   passengers = null;
   nights = null;

   constructor (props) {
    super(props)
   }
   

   componentDidMount () {
    //https://blog.afnu.co.il/wp-json/wp/v2/posts?slug=about
    axios.get(`https://blog.afnu.co.il/wp-json/wp/v2/posts/?slug=${this.props.match.params.id}`)
        .then(res => this.setState({
            posts: res.data,
            isLoaded: true,
            inlineStyles: this.inlineStyles,
        }, this.initializeSliders))
        .catch(err => console.log(err));

    }
   
    onFinish = (values) => {
        console.log('Form Values:', values);
        alert('תקלה: ההודעה לא נשלחה!!! ניתן לשלוח לנו הודעת וואטסאפ למספר 050-7801801')
    };
    

   render() {
        //const { postContent, inlineStyles } = this.state;

         // Get the query string from the URL
        const queryString = window.location.search;

        // Parse the query string using URLSearchParams
        const searchParams = new URLSearchParams(queryString);

        // Get the value of a specific parameter
        const booking_type = searchParams.get('type');
        
        if (booking_type != 'standard' && booking_type!='flex') {
            return <div>Invalid Parameter</div>;
        }
       

        let strActiveMenu = "main";
        let hideMainImage=false;
       
        return (
        

        <div className="App">  
        <link rel="stylesheet" href="/assets/bootstrap@5.1.3/css/bootstrap.min.css" />

        {/* Inline styles */}

            <Header activeMenu={strActiveMenu} hide={hideMainImage} hideSearchBox={true} className="hidden" hideSearch={true} />
            <main className={this.props.match.params.id} style={this.state.isLoaded?{}:{background:'#fff'}}>

            <link rel="stylesheet" href="/assets/bootstrap@5.1.3/css/bootstrap.min.css" />

            <link rel='stylesheet' id='wp-block-image-rtl-css' href='https://blog.afnu.co.il/wp-includes/blocks/image/style-rtl.min.css?ver=6.7.1' media='all' />

            <link rel="stylesheet" href="/assets/antd/dist/antd.css" />

            <div className={this.state.isLoaded?'wave':'hidden'}>
                    <svg viewBox="0 0 1500 62" fill="none" xmlns="http://www.w3.org/2000/svg" style={{minHeight:'40px'}}>
                        <path transform="scale(1)" fillRule="evenodd" clipRule="evenodd" d="M2748.4,38.1c-211.5-0.3-438.7,84.6-634.5-0.8c-190-82.9-394.4,85.4-609.5-0.7c-204.9-82.1-433,86.2-650.9-0.8
                C659-41.9,412.2,122.7,235.7,35c-157-78-314.4,87.7-513-0.6C-444.1-39.8-635.1,34-814,33.7v89h3562.4V38.1z" fill="#eee"></path></svg>
            </div>
            <img src='/images/Iphone-spinner-2.gif' width='64' height='64' className={this.state.isLoaded?'hidden':''}></img>

            <div style={{margin:'1em',maxWidth:'600px', width:'100%', margin:'0 auto', textAlign:'center', padding:'.5em'}}>

                {this.state.posts.map(post => {
                this.nights = post.nights;
                this.from_date = post.from_date;
                this.vacation_id = post.vacation_id;
                this.to_date = post.to_date;
                this.passengers = post.passengers;

                return (
                    <div key={post.slug} id="vacation">
                        <h1 dangerouslySetInnerHTML={{__html: "טופס הזמנת " + post.title.rendered}}></h1>
                        {<img src={post.jetpack_featured_media_url} style={{marginLeft:'auto', marginRight:'auto', marginBottom:'1em',borderRadius:'5px',maxWidth:'100%',height:'auto'}}></img>}
                        {/*<div dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>*/}
                    </div>
                    )}
                )}
            </div>
            

            <div className="container my-1 booking_form">
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    initialValues={{
                        destination: 'סיישל',
                        meal: 'רגיל',
                    }}
                >


                     {/* Vacation Details */}
                     <h3 className="mb-3">פרטי החופשה</h3>
                    <div className="row details" style={{direction:'rtl'}}>
                        <div className="col-md-12">
                            <strong>קוד הזמנה: </strong> {this.vacation_id}{booking_type=='flex'?'FLX':''}
                        </div>
                        <div className="col-md-12">
                            <strong>תאריכים : </strong>
                            {this.from_date} עד {this.to_date} ({this.nights} לילות)
                        </div>
                        <div className="col-md-12">
                            <strong>הרכב: </strong>
                            {this.passengers}
                        </div>

                      
                    </div>


                    {/* Personal Details */}
                    <h3 className="mb-3">פרטי המזמין</h3>
                    <div className="row contact_form">
                    <div className="col-md-6">
                        <Form.Item
                        label="שם פרטי"
                        name="firstName"
                        rules={[{ required: true, message: 'נא להזין שם פרטי' }]}
                        >
                        <Input />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item
                        label="שם משפחה"
                        name="lastName"
                        rules={[{ required: true, message: 'נא להזין שם משפחה' }]}
                        >
                        <Input />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item
                        label="אימייל"
                        name="email"
                        rules={[
                            { required: true, message: 'נא להזין אימייל' },
                            { type: 'email', message: 'אימייל לא תקין' },
                        ]}
                        >
                        <Input />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item
                        label="מספר טלפון נייד (עם וואטסאפ)"
                        name="phone"
                        rules={[
                            { required: true, message: 'נא להזין מספר טלפון' },
                            { pattern: /^[0-9]{10}$/, message: 'מספר טלפון לא תקין' },
                        ]}
                        >
                        <Input />
                        </Form.Item>
                    </div>
                    </div>

                   
                    {/* Passenger Details */}
                    <h3 className="mb-3">פרטי הנוסעים</h3>
                    <Form.List name="passengers">
                    {(fields, { add, remove }) => (
                        <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <div key={key} className="row mb-3 passengers_form">
                                <div className="col-md-3">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'passengerFirstName']}
                                        fieldKey={[fieldKey, 'passengerFirstName']}
                                        rules={[{ required: true, message: 'נא להזין שם פרטי' }]}
                                        >
                                        <Input placeholder="שם פרטי" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-3">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'passengerLastName']}
                                        fieldKey={[fieldKey, 'passengerLastName']}
                                        rules={[{ required: true, message: 'נא להזין שם משפחה' }]}
                                        >
                                        <Input placeholder="שם משפחה" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-4">
                                    <Form.Item  
                                        {...restField} 
                                        fieldKey={[fieldKey, 'passengerBirthDate']}
                                        rules={[{ required: true, message: "נא להזין תאריך לידה" }]}>
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="תאריך לידה"
                                            rules={[{ required: true, message: "נא להזין תאריך לידה" }]}
                                            /*onChange={(date) =>
                                            this.handlePassengerChange(index, "birthDate", date)
                                            }*/
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-1 align-items-center" style={{ margin:0, padding:0 }}>
                                    <Button type="link" className="remove_btn" danger onClick={() => remove(name) }>X</Button>
                            </div>

                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                            הוסף נוסע
                            </Button>
                        </Form.Item>
                        </>
                    )}
                    </Form.List>

                    {/* Additional Notes */}
                    <br />
                    <h3 className="mb-3">הערות נוספות</h3>
                    <Form.Item name="notes" className="remarks">
                    <Input.TextArea rows={4} />
                    </Form.Item>
                    
                    <br />
                    <Form.Item style={{margin:'2em 0'}}>
                    <Button type="primary" htmlType="submit" block>
                        שלח הזמנה
                    </Button>
                    </Form.Item>
                    <br />
                </Form>
                </div>
            </main>
            <Footer />

            
        </div>
       )
   }
}
export default BookingForm